import React, { useState } from 'react'


export default () => {
  const [hover, setHover] = useState(false)

  const onMouseOver = () => {
    setHover(true)
  };

  const onMouseLeave = () => {
    setHover(false)
  };

  return (
    <div
      onMouseOver={() => onMouseOver()}
      onMouseLeave={() => onMouseLeave()}
      role="presentation" // To satisfy the WAI-ARIA accessibility
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        viewBox="100 100 800 800"
      >
        <g id="pane4">
          <g id="Froggo_copy_3" data-name="Froggo copy 3">
            <ellipse
              cx="497.5"
              cy="682.5"
              rx={119}
              ry={12}
              style={{ fill: "#f2f2f2" }}
            />
            <path
              d="M384.5,414.5l6,117-6,112s-4,41,33,41,36-33,36-33,2-27,43-27,40,25,40,25,2,30,35,30,35.8-17,35-30c-9-146,0-243,0-243s-1-42-32-42-37,16-37,38-74,29-74,0-23-32-38-32S380.5,373.5,384.5,414.5Z"
              style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
            />
            <path
              d="M462.5,452.5c1.9-2.9,7.3,17,56,5"
              style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
            />
            <g id="toggle-4" data-name="toggle">
              {hover ?
                <path
                  id="hover-2"
                  data-name="hover"
                  d="M533.5,525.5c9,38,1,67,29,65s15-59,9-84"
                  style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
                />
                :
                <path
                  id="nohover-2"
                  data-name="nohover"
                  d="M533.5,524.5c9,38,15.1,65.8,43,63,20-2,1-57-5-82"
                  style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
                />
              }
            </g>
            <path
              d="M443,547.4c15.8-17.3,32.5-80.9,25.1-88.8s-27-32.3-70.2,82.3"
              style={{
                fill: "#22b573",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: "1.04938805103302px"
              }}
            />
            <circle
              cx="571.5"
              cy="394.5"
              r={11}
              style={{
                stroke: "#fff",
                strokeMiterlimit: 10,
                strokeWidth: "0.824802008914543px"
              }}
            />
            <circle
              id="open"
              cx="422.5"
              cy="394.5"
              r={11}
              style={{ stroke: "#fff", strokeMiterlimit: 10 }}
            />
            <g id="Layer_14_copy_3" data-name="Layer 14 copy 3">
              <circle cx="471.5" cy="568.5" r="3.5" style={{ fill: "#a5c447" }} />
              <circle cx="579.5" cy="476.5" r="3.5" style={{ fill: "#a5c447" }} />
              <circle cx={410} cy={446} r={4} style={{ fill: "#a5c447" }} />
              <circle cx={414} cy={607} r={4} style={{ fill: "#a5c447" }} />
              <circle cx={593} cy={631} r={3} style={{ fill: "#a5c447" }} />
              <circle cx="531.5" cy="605.5" r="2.5" style={{ fill: "#a5c447" }} />
              <circle cx="409.5" cy="656.5" r="3.5" style={{ fill: "#a5c447" }} />
              <circle cx="420.5" cy="473.5" r="2.5" style={{ fill: "#a5c447" }} />
              <circle cx={442} cy={526} r={2} style={{ fill: "#a5c447" }} />
              <circle cx={536} cy={427} r={2} style={{ fill: "#a5c447" }} />
              <circle cx={515} cy={499} r={2} style={{ fill: "#a5c447" }} />
              <circle cx="563.5" cy="542.5" r="1.5" style={{ fill: "#a5c447" }} />
              <circle cx="590.5" cy="551.5" r="2.5" style={{ fill: "#a5c447" }} />
            </g>
          </g>
          <text
            transform="translate(380.6 285.3)"
            style={{ fontSize: 36, fontFamily: "FredokaOne-Regular, Fredoka One" }}
          >
            <tspan style={{ letterSpacing: "-0.039998372395833336em" }}>L</tspan>
            <tspan
              x="19.7"
              y={0}
              style={{ letterSpacing: "-0.009996202256944444em" }}
            >
              e
            </tspan>
            <tspan x={40} y={0}>
              t me think...
            </tspan>
          </text>
        </g>
      </svg>

    </div>
  )
}