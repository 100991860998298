import React, { useState } from 'react'


export default () => {
  const [hover, setHover] = useState(false)

  const onMouseOver = () => {
    setHover(true)
  };

  const onMouseLeave = () => {
    setHover(false)
  };

  return (
    <div
      onMouseOver={() => onMouseOver()}
      onMouseLeave={() => onMouseLeave()}
      role="presentation" // To satisfy the WAI-ARIA accessibility
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        viewBox="100 100 800 800"
      >
        <g id="pane5">
          <text
            transform="translate(478.5 253)"
            style={{ fontSize: 36, fontFamily: "FredokaOne-Regular, Fredoka One" }}
          >
            ...
          </text>
        </g>
        <g id="pane6">
          <g id="Froggo_copy_5" data-name="Froggo copy 5">
            <ellipse
              cx="487.5"
              cy="658.5"
              rx={119}
              ry={12}
              style={{ fill: "#f2f2f2" }}
            />
            <path
              d="M374.5,390.5v229s-4,41,33,41,36-33,36-33,2-27,43-27,40,25,40,25,2,30,35,30,35-17,35-30v-243s0-36-31-36-38,10-38,32-74,29-74,0-23-32-38-32S374.5,346.5,374.5,390.5Z"
              style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
            />
            <path
              d="M457.5,431.5s-15-35,51,2"
              style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
            />
            <path
              d="M540.5,507.5c49,18-14.1,53.1,14,54,36.3,1.2,92.9-65,9-84"
              style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
            />
            <path
              d="M441,529.4c15.8-17.3,32.5-80.9,25.1-88.8s-27.3-25.7-70.6,88.9"
              style={{
                fill: "#22b573",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: "1.04938805103302px"
              }}
            />
            <g id="toggle-4" data-name="toggle">
              {hover ?
                <path
                  id="hover-2"
                  data-name="hover"
                  d="M425.5,370.5c0,6.1-6.9,5-13,5s-12,1.1-12-5,5.9-6,12-6S425.5,364.4,425.5,370.5Z"
                  style={{ stroke: "#fff", strokeMiterlimit: 10 }}
                />
                :
                <circle
                  id="nohover-2"
                  data-name="nohover"
                  cx="561.5"
                  cy="370.5"
                  r={11}
                  style={{
                    stroke: "#fff",
                    strokeMiterlimit: 10,
                    strokeWidth: "0.824802008914543px"
                  }}
                />
              }
              {hover ?
                <path
                  id="hover-3"
                  data-name="hover"
                  d="M574.5,370.5c0,6.1-6.9,5-13,5s-12,1.1-12-5,5.9-6,12-6S574.5,364.4,574.5,370.5Z"
                  style={{ stroke: "#fff", strokeMiterlimit: 10 }}
                />
                :
                <circle
                  id="nohover-3"
                  data-name="nohover"
                  cx="412.5"
                  cy="370.5"
                  r={11}
                  style={{ stroke: "#fff", strokeMiterlimit: 10 }}
                />
              }
            </g>
            <g id="Layer_14_copy_5" data-name="Layer 14 copy 5">
              <circle cx="461.5" cy="544.5" r="3.5" style={{ fill: "#a5c447" }} />
              <circle cx="569.5" cy="452.5" r="3.5" style={{ fill: "#a5c447" }} />
              <circle cx={400} cy={422} r={4} style={{ fill: "#a5c447" }} />
              <circle cx={404} cy={583} r={4} style={{ fill: "#a5c447" }} />
              <circle cx={583} cy={607} r={3} style={{ fill: "#a5c447" }} />
              <circle cx="521.5" cy="581.5" r="2.5" style={{ fill: "#a5c447" }} />
              <circle cx="399.5" cy="632.5" r="3.5" style={{ fill: "#a5c447" }} />
              <circle cx="410.5" cy="449.5" r="2.5" style={{ fill: "#a5c447" }} />
              <circle cx={432} cy={502} r={2} style={{ fill: "#a5c447" }} />
              <circle cx={526} cy={403} r={2} style={{ fill: "#a5c447" }} />
              <circle cx={505} cy={475} r={2} style={{ fill: "#a5c447" }} />
              <circle cx="553.5" cy="518.5" r="1.5" style={{ fill: "#a5c447" }} />
              <circle cx="580.5" cy="527.5" r="2.5" style={{ fill: "#a5c447" }} />
            </g>
          </g>
        </g>
      </svg>

    </div>
  )
}