import React, { useState } from 'react'


export default () => {
  const [hover, setHover] = useState(false)

  const onMouseOver = () => {
    setHover(true)
  };

  const onMouseLeave = () => {
    setHover(false)
  };

  return (
    <div
      onMouseOver={() => onMouseOver()}
      onMouseLeave={() => onMouseLeave()}
      role="presentation" // To satisfy the WAI-ARIA accessibility
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        viewBox="100 100 800 800"
      >
        <g id="pane9">
          <g id="Froggo_copy_8" data-name="Froggo copy 8">
            <ellipse
              cx="503.5"
              cy="655.5"
              rx={119}
              ry={12}
              style={{ fill: "#f2f2f2" }}
            />
            <path
              d="M394.5,394.5l-1,67,2,72-5,83s-4,41,33,41,36-33,36-33,2-27,43-27,40,25,40,25,2,30,35,30,35-17,35-30c0-8.7-10.3-112.2-4-181,4-44-1-60-1-60s-1-25-25-25c-31,0-39,6-45,25-6.6,21-61.3,22.5-75-3-7-13-16-21-31-21S393.5,368.5,394.5,394.5Z"
              style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
            />
            <g id="Layer_14_copy_8" data-name="Layer 14 copy 8">
              <circle cx="477.5" cy="541.5" r="3.5" style={{ fill: "#a5c447" }} />
              <circle cx="585.5" cy="449.5" r="3.5" style={{ fill: "#a5c447" }} />
              <circle cx={416} cy={419} r={4} style={{ fill: "#a5c447" }} />
              <circle cx={420} cy={580} r={4} style={{ fill: "#a5c447" }} />
              <circle cx={599} cy={604} r={3} style={{ fill: "#a5c447" }} />
              <circle cx="537.5" cy="578.5" r="2.5" style={{ fill: "#a5c447" }} />
              <circle cx="415.5" cy="629.5" r="3.5" style={{ fill: "#a5c447" }} />
              <circle cx="426.5" cy="446.5" r="2.5" style={{ fill: "#a5c447" }} />
              <circle cx={448} cy={499} r={2} style={{ fill: "#a5c447" }} />
              <circle cx={542} cy={400} r={2} style={{ fill: "#a5c447" }} />
              <circle cx={521} cy={472} r={2} style={{ fill: "#a5c447" }} />
              <circle cx="569.5" cy="515.5" r="1.5" style={{ fill: "#a5c447" }} />
              <circle cx="596.5" cy="524.5" r="2.5" style={{ fill: "#a5c447" }} />
            </g>
            <path
              d="M475.5,478.5c.4-2.9,32-105,57,0"
              style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
            />
            <circle
              cx="572.8"
              cy="386.7"
              r={11}
              style={{
                stroke: "#fff",
                strokeMiterlimit: 10,
                strokeWidth: "0.824802008914543px"
              }}
            />
            <circle
              cx="435.5"
              cy="390.5"
              r={11}
              style={{ stroke: "#fff", strokeMiterlimit: 10 }}
            />
            <g id="toggle-4" data-name="toggle">
              {hover ?
                <path
                  id="hover-2"
                  data-name="hover"
                  d="M592.3,474.7c-6.8-58.5,4.2-102.3-26.5-100.9S544.6,463,549.2,501.5"
                  style={{
                    fill: "#22b573",
                    stroke: "#000",
                    strokeMiterlimit: 10,
                    strokeWidth: "1.2937283792797416px"
                  }}
                />
                :
                <path
                  id="nohover-2"
                  data-name="nohover"
                  d="M592.3,500.7c-6.8-58.5,4.2-102.3-26.5-100.9S544.6,489,549.2,527.5"
                  style={{
                    fill: "#22b573",
                    stroke: "#000",
                    strokeMiterlimit: 10,
                    strokeWidth: "1.2937283792797416px"
                  }}
                />
              }
              {hover ?
                <path
                  id="hover-3"
                  data-name="hover"
                  d="M410.1,470.7c6.8-58.5-4.3-102.3,26.5-100.9s21.1,89.2,16.6,127.7"
                  style={{
                    fill: "#22b573",
                    stroke: "#000",
                    strokeMiterlimit: 10,
                    strokeWidth: "1.2937283792797416px"
                  }}
                />
                :
                <path
                  id="nohover-3"
                  data-name="nohover"
                  d="M410.1,496.7c6.8-58.5-4.3-102.3,26.5-100.9s21.1,89.2,16.6,127.7"
                  style={{
                    fill: "#22b573",
                    stroke: "#000",
                    strokeMiterlimit: 10,
                    strokeWidth: "1.2937283792797416px"
                  }}
                />
              }
            </g>
          </g>
          <g id="Layer_22" data-name="Layer 22">
            <text
              transform="translate(372.7 243.7)"
              style={{ fontSize: 36, fontFamily: "FredokaOne-Regular, Fredoka One" }}
            >
              God. I’m boring.
            </text>
          </g>
        </g>
      </svg>
    </div>
  )
}