import React, { useState } from 'react'


export default () => {
  const [hover, setHover] = useState(false)

  const onMouseOver = () => {
    setHover(true)
  };

  const onMouseLeave = () => {
    setHover(false)
  };

  return (
    <div
      onMouseOver={() => onMouseOver()}
      onMouseLeave={() => onMouseLeave()}
      role="presentation" // To satisfy the WAI-ARIA accessibility
    >
      <svg
        id="pane1"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        viewBox="100 100 800 800"
      >
        <g id="speech">
          <text
            transform="translate(288.4 276.4)"
            style={{ fontSize: 36, fontFamily: "FredokaOne-Regular, Fredoka One" }}
          >
            Hello! My name is{" "}
            <tspan
              x="310.4"
              y={0}
              style={{ letterSpacing: "-0.059977213541666664em" }}
            >
              F
            </tspan>
            <tspan
              x="331.1"
              y={0}
              style={{ letterSpacing: "-0.030002170138888888em" }}
            >
              r
            </tspan>
            <tspan x="346.5" y={0}>
              oggo.
            </tspan>
          </text>
        </g>
        <g id="Froggo">
          <ellipse
            cx="502.5"
            cy="668.5"
            rx={119}
            ry={12}
            style={{ fill: "#f2f2f2" }}
          />
          <path
            d="M389.5,400.5l2,135-2,94s-4,41,33,41,36-33,36-33,2-27,43-27,40,25,40,25,2,30,35,30,35-17,35-30c0-6.3-4-63.5-4-121,0-60.8,4-122,4-122s0-36-31-36-38,10-38,32-74,29-74,0-23-32-38-32S389.5,356.5,389.5,400.5Z"
            style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
          />
          <path
            d="M472.5,441.5s23,26,51,2"
            style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
          />
          <path
            d="M538.5,511.5c9,38,1,67,29,65s15-59,9-84"
            style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
          />
          <g id="toggle">
            {hover ?
              <path
                id="hover"
                d="M415.9,493c-18.1-14.9-84.6-34.8-89.2-24.7-3.6,7.9-30.2,24.3,85.8,66.2"
                style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
              />
              :
              <path
                id="nohover"
                d="M416.5,493.5c-15-18-76.5-50.1-83-41-5,7-27,19,79,82"
                style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
              />
            }
          </g>
          <circle
            cx="576.5"
            cy="380.5"
            r={11}
            style={{
              stroke: "#fff",
              strokeMiterlimit: 10,
              strokeWidth: "0.824802008914543px"
            }}
          />
          <circle
            id="open"
            cx="427.5"
            cy="380.5"
            r={11}
            style={{ stroke: "#fff", strokeMiterlimit: 10 }}
          />
          <g id="Layer_14" data-name="Layer 14">
            <circle cx="476.5" cy="554.5" r="3.5" style={{ fill: "#a5c447" }} />
            <circle cx="584.5" cy="462.5" r="3.5" style={{ fill: "#a5c447" }} />
            <circle cx={415} cy={432} r={4} style={{ fill: "#a5c447" }} />
            <circle cx={419} cy={593} r={4} style={{ fill: "#a5c447" }} />
            <circle cx={598} cy={617} r={3} style={{ fill: "#a5c447" }} />
            <circle cx="536.5" cy="591.5" r="2.5" style={{ fill: "#a5c447" }} />
            <circle cx="414.5" cy="642.5" r="3.5" style={{ fill: "#a5c447" }} />
            <circle cx="425.5" cy="459.5" r="2.5" style={{ fill: "#a5c447" }} />
            <circle cx={447} cy={512} r={2} style={{ fill: "#a5c447" }} />
            <circle cx={541} cy={413} r={2} style={{ fill: "#a5c447" }} />
            <circle cx={520} cy={485} r={2} style={{ fill: "#a5c447" }} />
            <circle cx="568.5" cy="528.5" r="1.5" style={{ fill: "#a5c447" }} />
            <circle cx="595.5" cy="537.5" r="2.5" style={{ fill: "#a5c447" }} />
          </g>
        </g>
      </svg>

    </div>

  )
}